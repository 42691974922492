import React from "react";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useNavigate } from 'react-router-dom';

const SignInButton = styled.div`
  width: 85%;
  height: 52px;
  border-radius: 10px;
  background-color: ${(props) => (props.disabled ? '#ced4da' : '#4d59e0')};
  color: white;
  display: flex;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  justify-content: center;
  align-items: center;
`;

export const SignInPage = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();

    const handleLoginPopup = () => {
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: '/redirect',
            })
            .catch((error) => console.log(error));
    };

    const handleForgotPassword = (event) => {
        event.preventDefault();
        navigate('/forgetPassword');
    };

    return (
        <header>
            <div className="overlay"></div>
            <img className="img-background-login" src="https://connect-iam.cocentric.ai/images/connect_login.png" alt="background image"></img>
            <div className="mainsecion h-100">
                <div className=" h-100 ">
                    <div className="row h-100 w-100">
                        <div className="align-self-center col-lg-6 col-sm-12 d-sm-flex justify-content-center offset-lg-6  ">
                            <div className="col-md-6 align-self-center">
                                <div className=" card-custom ">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                        <img className="img-responsive d-lg-block" style={{ marginLeft: '-5%', marginTop: '5%', marginBottom: '5%' }} src="https://connect.cocentric.ai/static/media/connectcocentric.a8a709ea.svg"></img>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', paddingBottom: '20px' }}>
                                        <SignInButton onClick={handleLoginPopup}>
                                            <FontAwesomeIcon icon={faSignIn} />
                                            <h6 style={{ marginBottom: 0, marginLeft: '10px' }}>Sign In</h6>
                                        </SignInButton>
                                    </div>
                                    <div className="footer-card">
                                        <div className="links-row">
                                            <div className="forgot-password">
                                                <a className="forgetPassword" href="#" onClick={handleForgotPassword}>Forgot password?</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
