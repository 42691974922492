import React, { useState, useRef } from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../authConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async"; // Use Helmet from react-helmet-async

const ItemsContainerLoading = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ForgetPassword = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState(""); // State to store the input value
  const [isVerified, setIsVerified] = useState(false); // To track reCAPTCHA verification
  const recaptchaRef = useRef(null); // Create a ref for reCAPTCHA
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setEmail(event.target.value); // Update the state with the input's value
  };

  const handleReCAPTCHA = (value) => {
    setIsVerified(!!value); // Set true if reCAPTCHA is verified
  };

  const resetReCAPTCHA = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset(); // Reset the reCAPTCHA
    }
    setIsVerified(false); // Update the verification state
  };

  const handleBackLogin = (event) => {
    event.preventDefault();
    setShowError(false);
    setShowSuccess(false);
    resetReCAPTCHA();
    setEmail("");
    setIsLoading(false);
    navigate("/");
  };

  const handleForgetPassword = async () => {
    if (email !== "" && isVerified) {
      setShowSuccess(false);
      setShowError(false);
      setIsLoading(true);
      try {
        await axios.post(
          config.connectEndpoint + "/sso/resetPassword",
          JSON.stringify(email),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setShowError(false);
        setShowSuccess(true);
        resetReCAPTCHA();
        setIsLoading(false);
        setEmail('');
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Axios Error:", error.response?.data || error.message);
          setShowSuccess(false);
          setShowError(true);
          setIsLoading(false);
          resetReCAPTCHA();
          setEmail('');
        } else {
          console.error("Unexpected Error:", error);
          setShowSuccess(false);
          setShowError(true);
          setIsLoading(false);
          resetReCAPTCHA();
          setEmail('');
        }
      }
      resetReCAPTCHA();
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        {/* Fonts */}
        <link
          href="//fonts.googleapis.com/css?family=Roboto:500,700"
          rel="stylesheet"
          type="text/css"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
          rel="stylesheet"
        />
        {/* Meta Tag */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <style>
          {`
                /* Base styles */
                body {
                    margin: 0;
                    font-family: "Inter", serif;
                    height: 100%;
                    background-color: #3E3E3E;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                #root {
                    margin: 0;
                    font-family: "Inter", serif;
                    height: 100%;
                    background-color: #3E3E3E;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                html {
                    height: 100%;
                    width: 100%;
                }

                #api-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                /* Main Container with Image and Form */
                .main-container {
                    display: flex;
                    width: 100%;
                    max-width: 600px;
                    background-color: #E9E3DD;
                    border-radius: 8px;
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                    height: 650px;
                    margin: 3%;
                }

                /* Right Form Section */
                .form-section {
                    width: 100%;
                    padding: 80px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .entry-item label {
                    display: none;
                }

                .rememberMe {
                    display: none;
                }

                .form-section h2 {
                  font-size: 24px;
                  font-weight: 600;
                  color: #000000;
                  margin-bottom: 10px;
                }

                .form-section p {
                    font-size: 16px;
                    font-weight: 300;
                    color: #000000;
                    margin-bottom: 20px;
                }

                /* Form Styles from #api */
                #api {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    background-color: #E9E3DD; /* Matches modal background */
                    border-radius: 8px;
                    margin-bottom: 35px;
                }

                .entry-item input {
                    width: 90% !important;
                    padding: 16px !important;
                    padding-left: 40px !important; /* Space for icons */
                    margin-bottom: 10px !important;
                    border: 1px solid #cccccc !important;
                    border-radius: 8px !important;
                    font-size: 16px !important;
                    font-weight: 400 !important;
                    background-color: #ffffff !important;
                    color: #000000 !important;
                    background-repeat: no-repeat !important;
                    background-position: 12px center !important;
                    background-size: 21px !important;
                    font-family: "Inter", serif !important;
                }

                /* Username icon */
                #signInName {
                    background-image: url('https://iili.io/2iFcnxR.png');
                }

                #password {
                    background-image: url('https://iili.io/2iFEZjs.png');
                }

                .buttons {
                    width: 160px;
                    padding-top: 10px;
                }

                .buttons button {
                    width: 100%;
                    padding: 15px;
                    background-color: #A3BBAD;
                    border: none;
                    border-radius: 8px;
                    color: #ffffff;
                    font-size: 16px;
                    cursor: pointer;
                    text-transform: capitalize;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    line-height: 20px;
                    font-family: "Inter", serif;
                    height: 51px;
                    color: #000000;
                    font-weight: 500;
                }

                .buttons button:hover {
                    background-color: #778a7f;
                }

                .buttons button:disabled {
                    width: 100%;
                    padding: 15px;
                    background-color:rgb(132, 133, 135);
                    border: none;
                    border-radius: 8px;
                    color: #ffffff;
                    font-size: 16px;
                    cursor: pointer;
                    text-transform: capitalize;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    line-height: 20px;
                    font-family: "Inter", serif;
                    cursor: not-allowed; /* Prevent pointer interactions */
                }

                .buttons button:disabled:hover {
                    background-color: rgb(132, 133, 135); 
                    cursor: not-allowed;
                }

                .intro {
                    display: none;
                }

                /* Footer Section */
                .footer {
                    margin-top: 0px;
                    font-size: 16px !important;
                    color: #000000;
                    font-family: "Inter", serif;
                    line-height: 24px;
                    font-weight: 300 !important;
                    text-align: left;
                    width: 100%;
                }

                .footer a {
                    color: #2F1FAF;
                    font-weight: 600;
                    text-decoration: underline;
                    background: transparent;
                    padding: 0;
                    border-radius: 0%;
                }

                .footer a:hover {
                    text-decoration: none;
                    background: transparent;
                    padding: 0;
                    border-radius: 0%;
                }

                /* New Text Section */
                .text-section {
                    text-align: left;
                    margin-bottom: 35px;
                    font-size: 16px;
                    font-family: "Inter", serif;
                    color: #000000;
                    width: 100%;
                    line-height: 24px;
                }

                .text-section h2 {
                    font-size: 22px;
                    font-weight: 500;
                    color: #000000;
                    line-height: 29px;
                    padding-bottom: 20px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }

                .forgetPasswordLink {
                    margin-bottom: 0px !important;
                }

                .text-section span {
                    font-family: "Inter", serif;
                    font-weight: 300;
                    font-size: 18px;
                    color: #000000;
                    margin: 0;
                    display: flex;
                    align-items: center;
                }

                .forgetPasswordLink {
                    margin-bottom: 0px !important;
                }

                /* Footer Branding */
                .branding-one {
                    text-align: left;
                    font-size: 12px;
                    color: #6C6C6C;
                    font-family: "Inter", serif;
                    line-height: 17px;
                    position: relative;
                    top: 125%;
                    right: 20%;
                }

                .branding-two {
                    text-align: left;
                    font-size: 12px;
                    color: #6C6C6C;
                    font-family: "Inter", serif;
                    line-height: 17px;
                    position: relative;
                    top: 127%;
                    right: 20%;
                }

                /* Footer Branding */
                .logo-one {
                    margin-bottom: 20px;
                    text-align: center;
                    position: relative;
                    top: -15%;
                    left: 0%;
                }

                .logo-one img {
                    max-width: 150px;
                    height: auto;
                }

                /* Logo Section */
                .logo {
                    margin-bottom: 20px;
                    text-align: center;
                    position: absolute;
                    top: 15%;
                    display: none;
                }

                .logo img {
                    max-width: 150px;
                    height: auto;
                }

                .branding-one a {
                    color: #6C6C6C;
                    text-decoration: underline;
                    background: transparent;
                    padding: 0;
                    border-radius: 0%;
                }

                .branding-one a:hover {
                  text-decoration: none;
                }

                .branding-two a {
                    color: #6C6C6C;
                    text-decoration: underline;
                    background: transparent;
                    padding: 0;
                    border-radius: 0%;
                }

                /* Footer Branding */
                .branding {
                    text-align: left;
                    font-size: 12px;
                    color: #6C6C6C;
                    font-family: "Inter", serif;
                    line-height: 17px;
                    position: relative;
                    top: 101%;
                    display: none;
                }

                .branding a {
                    color: #6C6C6C;
                    text-decoration: underline;
                    background: transparent;
                    padding: 0;
                    border-radius: 0%;
                }

                .text-section-span {
                    padding-bottom: 20px;
                }

                #forgotPassword {
                    display: none !important;
                }

                .divider {
                    display: none !important;
                }

                .success-message {
                    font-size: 14px !important;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    padding: 0px;
                    color: #4d59e0 !important;
                }
                .error-message {
                    font-size: 14px !important;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    padding: 0px;
                    color: red !important;
                }

                .footer-p {
                  margin-bottom: 30% !important;
                }

                /* Mobile-Specific Styles */
                @media (max-width: 768px) {
                    body {
                        display: flex;
                        flex-direction: column;
                        justify-content: unset;
                    }

                    #api {
                      margin-bottom: 0px;
                    }

                    #root {
                        display: flex;
                        flex-direction: column;
                        justify-content: unset;
                    }

                    .logo {
                        margin-bottom: 10px;
                        text-align: center;
                        display: block;
                        position: initial;
                        margin-top: 20px;
                    }

                    .logo img {
                        width: 100px;
                    }

                    .main-container {
                        flex-direction: column;
                        width: 90%;
                        height: auto;
                    }

                    .branding-one {
                        display: none;
                    }

                    .branding-two {
                        display: none;
                    }

                    .footer-p {
                      margin-bottom: 5% !important;
                    }

                    .text-section {
                        width: 94%;
                        margin-bottom: 15px;
                        margin-top: 25px;
                    }

                    .text-section h2 {
                        padding-bottom: 10px;
                        margin-bottom: 8px;
                    }

                    .text-section-span {
                        padding-bottom: 10px;
                        margin-bottom: 8px !important;
                    }

                    .form-section {
                        padding: 20px;
                    }

                    .entry-item input {
                        width: 80%;
                    }

                    .footer {
                        margin-top: 30px;
                        line-height: 20px;
                        margin-bottom: 20px;
                    }

                    .form-section p {
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }

                    .forgetPasswordLink {
                        margin-bottom: 10px !important;
                    }

                    .logo-one {
                        display: none;
                    }
                    

                    .branding {
                        text-align: left;
                        font-size: 12px;
                        color: #6C6C6C;
                        font-family: "Inter", serif;
                        line-height: 17px;
                        width: 90%;
                        margin-left: 3%;
                        position: initial;
                        display: block;
                    }
                }

                @media (max-width: 400px) { 
                    .entry-item input {
                        width: 70%;
                    }
                }

                @media (max-width: 335px) {
                    .form-section {
                        width: 88%;
                    }

                    .entry-item input {
                        width: 80%;
                    }
                }
            `}
        </style>
      </Helmet>
      <>
        <div className="logo">
          <img src="https://iili.io/2i3ZAKv.png" alt="Ennismore Logo" />
        </div>

        <div className="main-container">
          <div className="form-section">
            <div className="logo-one">
              <img src="https://iili.io/2i3ZAKv.png" alt="Ennismore Logo" />
            </div>
            <div className={showError || showSuccess ? "branding-two" : "branding-one"}>
              EnnismoreID <br />
              powered by <a href="#">Connect by Cocentric</a>
            </div>
            <div className="text-section">
              <span className="text-section-span">
                EnnismoreID
              </span>
              <h2>Forget your password?</h2>
              <p style={{ marginTop: 0, marginBottom: 0 }}>
                Please enter your username associated with your account to
                request a password reset.
              </p>
            </div>
            <div id="api-container">
              <div id="api" data-name="Unified">
                <div>
                  <div className="entry">
                    <div style={{ display: 'flex' }}>
                        {showSuccess && <div className="form-group" style={{ width: '100%' }}>
                            <p className="success-message" style={{ marginBottom: '5px'}}>You will receive password reset instructions to your email soon</p>
                        </div>}
                        {showError && <div className="form-group" style={{ width: '100%' }}>
                            <p className="error-message" style={{ marginBottom: '5px'}}>Error while trying to forget password, please try again later</p>
                        </div>}
                    </div>
                    <div className="entry-item">
                      <label htmlFor="signInName">Sign in name</label>
                      <input
                        type="email"
                        disabled={loading}
                        aria-describedby="emailHelp"
                        placeholder="e.g ENN000"
                        autoFocus=""
                        data-val="true"
                        data-val-required="The Username field is required."
                        name="Username"
                        value={email}
                        onChange={handleInputChange}
                        id="signInName"
                        title="Please enter a valid Sign in name"
                        aria-label="Sign in name"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <ReCAPTCHA
                        sitekey="6LcBGl4UAAAAAFLEwhRMMQhSJxXRFGz28qlU63vp" // Replace with your site key
                        onChange={handleReCAPTCHA} // Handle verification
                        ref={recaptchaRef}
                      />
                    </div>
                    <div className="buttons">
                      <button
                        onClick={handleForgetPassword}
                        disabled={email == "" || !isVerified || loading}
                      >
                        {!loading && (
                          "Reset password"
                        )}
                        {loading && (
                          <ItemsContainerLoading>
                            <span className="loader-reset"></span>
                          </ItemsContainerLoading>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                <p className="footer-p">
                  <span style={{ fontWeight: 600 }}>Need help?</span> Contact{" "}
                  <a href="mailto:support@cocentric.com" target="_blank">
                    Support
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="branding">
          EnnismoreID <br />
          powered by{" "}
          <a href="https://connect.cocentric.ai/" target="_blank">
            Connect by Cocentric
          </a>
        </div>
      </>
    </HelmetProvider>
  );
};
